import React from "react"
import Layout from "../../Layout"
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Spacer from "../../components/Spacer"
import StaticHeadline from "../../components/static/StaticHeadline"
import StaticParagraph from "../../components/static/StaticParagraph"
import StaticContainer from "../../components/static/StaticContainer"
import StaticImage from "../../components/static/StaticImage"

import usePagesImages from "../../hooks/usePagesImages"

export default function LiterBenzinAktuellerPreis({ data }) {
  const fluid = usePagesImages("liter-benzin-aktueller-preis-1.jpg")
  const adac = usePagesImages("liter-benzin-aktueller-preis-2.jpg")

  return (
    <Layout>
      <Meta
        title="1 Liter Benzin – aktueller Preis und Vergleich"
        description="Wie ist der Benzinpreis 2021 für 1 Liter Benzin und wie hoch sind die Benzinpreise bei uns im Vergleich zu anderen europäischen Ländern? Tankchecker erklärt es Dir."
        image={fluid.src}
      />
      <Header />
      <main>
        <StaticContainer maxWidth="lg">
          <Spacer />
          <StaticHeadline variant="h1" align="center" gutterBottom>
            1 Liter Benzin – aktueller Preis und Vergleich
          </StaticHeadline>

          <StaticParagraph gutterBottom>
            Derzeit – also April 2021 - kostet der Liter Benzin in Deutschland
            im Schnitt deutlich mehr als noch vor 12 Monaten im April 2020. Vor
            genau einem Jahr hatte die Coronakrise gerade überall ihre erste
            Hochphase erreicht. Die Menschen befanden sich im ersten Lockdown
            hier in Deutschland, aber auch in allen Nachbarländern und weltweit.
            Die Wirtschaftsprognosen sahen für den Rest des Jahres nicht gut
            aus, man hatte noch keine Aussicht auf eine konkrete
            Impfstoffentwicklung und damit auf ein Ende der Pandemie. Die Preise
            für Kraftstoffe wie Benzin, Super E5, Super E10 und Diesel sanken
            von Februar bis Mai 2020 um durchschnittlich 20 Cent. Mitte Juni
            wurde zusätzlich auch noch eine Mehrwertsteuerabsenkung um drei
            Prozentpunkte beschlossen, die sich natürlich ab Juli 2020 auf den
            Literpreis für Benzin und Diesel auch auswirkte. Zwar stiegen die
            Spritpreise über die Sommermonate dann wieder ein wenig an – was mit
            Sicherheit auch mit den sinkenden Coronafallzahlen und wieder
            ansteigender Mobilität der Deutschen zu tun hatte – sanken dann aber
            mit Beginn der zweiten Viruswelle wieder um einige Cent. Der Verlauf
            lässt sich exemplarisch an den Durchschnittspreisen für Super-Benzin
            ganz gut anschauen:
          </StaticParagraph>
          <StaticImage
            fluid={fluid}
            alt="Durchschnittlicher Preis für einen Liter Superbenzin in Deutschland von Februar 2020 bis Februar 2021 (in Cent)"
            copyright="Bildnachweis: Statista"
          />
          <StaticParagraph gutterBottom>
            Ab der Jahreswende wurde der Preis für 1 Liter Benzin in Deutschland
            im Schnitt wieder deutlich teurer. Innerhalb von zwei Monaten
            kletterte er um knapp 20 Cent wieder auf das Ursprungsniveau von
            Anfang 2020. Das lag zum einen an der wieder aufgehobenen
            Mehrwertsteuersenkung: Seit Januar 2021 sind wieder 19 Prozent
            Mehrwertsteuer pro Liter Benzin fällig. Und zum anderen wurde die
            CO2-Steuer mit im Schnitt 7 Cent/Liter auf den Literpreis
            aufgeschlagen. Angesichts der
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Impfstoffentwicklungen ging man an der Börse davon aus, dass ein
            Ende der Pandemie in den kommenden Monaten und damit ein deutlicher
            Wirtschaftsaufschwung zu erwarten sei. Die Folge davon: An der Börse
            in Rotterdam stieg der Rohölpreis in Erwartung des kommenden
            Konjunkturaufschwungs wieder an. All diese Faktoren plus eine
            Verknappung der Rohölförderung in den USA sorgten dafür, dass die
            Preise seit Anfang 2021 wieder steigen.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wie ist der aktuelle Preis für 1 Liter Benzin in Deutschland?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Deutschland ist mit eines der teuersten Länder, wenn es um die
            Benzinpreise geht. Wobei Italien, Frankreich, Griechenland und
            Großbritannien sogar noch teuer sind, wie eine aktuelle Tabelle des
            ADAC aus dem März 2021 zeigt:
          </StaticParagraph>
          <StaticImage
            fluid={adac}
            alt="Die aktuellen Spritpreise in Europa"
            copyright="Bildnachweis: ADAC e.V."
          />
          <StaticParagraph gutterBottom>
            Nur die osteuropäischen Länder sowie Länder wie Luxemburg und
            Österreich sind da deutlich billiger.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Der Preis für 1 Liter Benzin in Deutschland stieg seit Anfang des
            Jahres spürbar wieder an. Erstmals seit Jahresbeginn sank der
            Benzinpreis Ende März um einige wenige Cent wieder – Grund dafür war
            der Preisrückgang für Rohöl.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Derzeit liegen die aktuellen Spritpreise im Durchschnitt bei 1,47
            Euro/Liter für Super E10 (Stand 06.04.2021) und pendeln sich da
            momentan ein. Der Preis für 1 Liter Diesel beträgt aktuell im
            Schnitt 1,31 Euro.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wie viel kostet 1 Liter Benzin in der Schweiz?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Aktuell unterscheiden sich die Benzinpreise in der Schweiz nicht
            allzu sehr von den Spritpreisen an deutschen Tankstellen. Zumindest
            wenn Dein Auto Benzin tankt. Anders sieht es aus, wenn Du Diesel
            tanken möchtest. Denn da sind die schweizerischen Preise für 1 Liter
            Diesel deutlich teurer als hier in Deutschland. Wenn Du also mit dem
            Auto in die Schweiz fahren möchtest, lohnt es sich, unbedingt noch
            einmal vor der Landesgrenze den Tank mit Dieselbenzin vollzumachen.
            Andersherum sparen die schweizerischen Dieselfahrer, wenn sie an
            einer deutschen Tankstelle erst tanken, denn hier unterscheiden sich
            die Literpreise für Super-Kraftstoffe und Diesel weiterhin
            erheblich. Noch günstiger wird es für den Schweizer, wenn er in
            Österreich tankt. Dort sind die Benzinpreise im Vergleich zu
            Deutschland noch einmal billiger und der Dieselpreis ist dort sogar
            noch ein paar Cent günstiger als hier in der BRD. Auch wer von
            Deutschland in die Niederlande fährt, sollte hier noch einmal
            volltanken: Gerade der Literpreis für Benzinsorten ist dort deutlich
            höher.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wie setzt sich der Benzinpreis 2021 in Deutschland zusammen?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Anhand einer Statista-Tabelle aus dem März 2021 zeigt sich, wie sich
            der Benzinpreis pro Liter zusammensetzt: So entfallen 65 Prozent des
            Liter-Preises in Deutschland auf die Steuerabgaben wie Ökosteuer,
            Energiesteuer, den Beitrag an den Erdölbevorratungsverband (EBV)
            sowie die Mehrwertsteuer. 28 Prozent des Gesamtpreises entsprechen
            dem Wareneinstandspreis/Produktpreis und nochmal 7 Prozent decken
            die sonstigen Kosten wie Vertrieb, Transport und Tankstellenpacht.
            An dem großen Anteil der Steuerabgaben können weder die
            Mineralölkonzerne noch die Tankstellenbetreiber etwas verändern.
            Aber je nach Wirtschaftslage oder umweltpolitischer Faktoren können
            die Kosten für Rohöl steigen oder sinken, was sich direkt in den
            Ölpreisen an der Börse in Rotterdam widerspiegelt. Auch gestiegene
            Vertriebs- oder Transportkosten geben die Mineralölkonzerne an die
            Verbraucher über die Benzinpreise an den Tankstellen weiter. Es
            lohnt sich also, sich vor dem Tanken zu informieren und online oder
            in Deiner Benzinpreis-App die Liter-Preise an den Tankstellen in der
            Nähe zu vergleichen. Auch vor Urlaubsfahrten lohnt es sich, den
            Benzinpreis im eigenen Land und den im Urlaubsland vorab sich
            anzusehen. Eventuell lohnt es sich ja, nochmal in Deutschland
            aufzutanken, wenn hier der Preis nicht so hoch ist wie am Zielort.
          </StaticParagraph>
          <Spacer />
          <Spacer />
        </StaticContainer>
      </main>
      <Footer />
    </Layout>
  )
}
